html, body {
    height: 100%;
}

body {
    display: flex;
    justify-content: center;
    color: #212529;
    background-color: #fff;
    font-family: 'Montserrat', sans-serif;
    /* box-shadow: inset 0 0 2rem rgba(0, 0, 0, .5); */
}

.cover-container {
    max-width: 42em;
}

.masthead {
    margin-bottom: 2rem;
}

.masthead-brand {
    margin-bottom: 0;
}

.nav-masthead .nav-link {
    padding: .25rem 0;
    font-weight: 700;
    color: #212529;
    background-color: transparent;
    border-bottom: .25rem solid transparent;
}

.nav-masthead .nav-link:hover, .nav-masthead .nav-link:focus {
    border-bottom-color: rgba(0, 0, 0, .25);
}

.nav-masthead .nav-link+.nav-link {
    margin-left: 1rem;
}

@media (min-width: 48em) {
    .masthead-brand {
        float: left;
    }
    
    .nav-masthead {
        float: right;
    }
}
    
.cover {
    padding: 0 1.5rem;
}
    
#background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
